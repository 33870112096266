//
//--Theme - Assan Multipurpose Theme
//--Author - Creative DM
//--Version - v4.7.1
//

//core bootstrap with custom scripts for tooltips,popovers,toast etc.
import './website/custom-bootstrap';

//bootstrap validations
import './website/form-validation';

//sticky header
import './website/header-sticky';

//preloader
import './website/preloader';

//back to top
import './website/topTop';


//Header sticky on reverse scroll
import './website/header-sticky-reverse';

//On scroll animations
import './website/aos';
// Lightbox for image/video popups
import './website/glightbox';
//Portfolio filter / masonry layouts
import './website/isotope-layout';
//background image/video parallax
import './website/jarallax';

//Smooth scroll
// import './website/smooth-scroll';
//Inject image[svg] to svg
// import './website/svgInjector';
//Typed Text
// import './website/typed';
//Element parallax
// import './website/rellax';
import '../sass/website/project.scss';
import 'glightbox/dist/css/glightbox.min.css';

// import Swiper JS
import Swiper from 'swiper';
import {Navigation, Pagination} from 'swiper/modules';

// configure Swiper to use modules
Swiper.use([Navigation, Pagination]);

new Swiper(".swiper-projects", {
    autoHeight: false,
    slidesPerView: 3,
    spaceBetween: 8,
    breakpoints: {
        // when window width is >= 320px
        320: {
            slidesPerView: 1,
            spaceBetween: 20
        },
        // when window width is >= 480px
        480: {
            slidesPerView: 1,
            spaceBetween: 30
        },
        // when window width is >= 640px
        640: {
            slidesPerView: 3,
            spaceBetween: 40
        }
    },
    pagination: {
        dynamicBullets: true,
        el: ".swiperProjects-pagination",
        clickable: true
    },
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
});

new Swiper(".swiper-logo", {
    autoHeight: false,
    lazy: true,
    slidesPerView: "auto",
    spaceBetween: 15,
    pagination: {
        dynamicBullets: true,
        el: ".swiperProjects-pagination",
        clickable: true
    },
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
});
